import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import useAxios, { configure } from "axios-hooks";

import User from "types/User";

interface AuthProviderProps {
  children: React.ReactNode;
}

interface AuthContextType {
  user: User;
  loading: boolean;
  signin: (email: string, password: string) => void;
  signout: () => void;
}

const AuthContext = createContext<AuthContextType>(null!);

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env["REACT_APP_ENDPOINT"],
});

configure({ axios: instance });

export function AuthProvider(props: AuthProviderProps) {
  const [user, setUser] = useState<any>(null);
  const [{ data, loading }] = useAxios("/auth");

  if (data?.status === "logged_in" && !user) {
    setUser(data.user);
  }

  async function signin(email: string, password: string) {
    const response = await instance.post(`/sign-in`, {
      user: {
        email: email,
        password: password,
      },
    });
    setUser(response.data.user);
  }

  async function signout() {
    await instance.get("/sign-out");
  }

  return (
    <AuthContext.Provider value={{ user, loading, signin, signout }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
