import React from "react";

interface TextfieldProps {
  id: string;
  label: string;
  value: string;
  type: "text" | "email" | "password";
  onChange: (value: string) => void;
}

export default function Textfield(props: TextfieldProps) {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    props.onChange(event.target.value);
  }

  return (
    <div>
      <label htmlFor={props.id}>{props.label}</label>
      <input
        id={props.id}
        type={props.type}
        value={props.value}
        onChange={handleChange}
      />
    </div>
  );
}
