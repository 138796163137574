import React, { useState } from "react";
import LoginForm from "./LoginForm";

import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { AxiosError } from "axios";

export default function LoginContainer() {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");

  const auth = useAuth();
  const navigate = useNavigate();

  async function onLogin(email: string, password: string) {
    setProcessing(true);
    setError("");

    try {
      await auth.signin(email, password);
      navigate("/", { replace: true });
    } catch (error) {
      processError(error);
    } finally {
      setProcessing(false);
    }
  }

  function processError(error: unknown) {
    setError("Something went wrong. Please try again later.");
    if (error instanceof AxiosError && error.response) {
      setError(error.response.data.message);
    }
  }

  return <LoginForm processing={processing} error={error} onSubmit={onLogin} />;
}
