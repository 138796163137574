import React, { useState } from "react";
import Textfield from "components/Textfield";
import Button from "components/Button";

interface LoginFormProps {
  processing: boolean;
  error: string;
  onSubmit: (email: string, password: string) => void;
}

export default function LoginForm(props: LoginFormProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    props.onSubmit(email, password);
  }

  return (
    <div>
      <h1>Frontstand</h1>
      {props.error && <p>{props.error}</p>}
      <form onSubmit={handleSubmit}>
        <Textfield
          id="email"
          label="Email Address"
          value={email}
          type="email"
          onChange={setEmail}
        />
        <Textfield
          id="password"
          label="Password"
          value={password}
          type="password"
          onChange={setPassword}
        />
        <Button
          type="submit"
          label="Log in"
          disabled={props.processing}
          onClick={() => {}}
        />
      </form>
    </div>
  );
}
