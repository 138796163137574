import React from "react";
import styled from "styled-components";

import { Post } from "graphql/graphql.generated";

const $Container = styled.ul`
  margin-bottom: 1rem;
`;

const $Post = styled.li``;

interface PostListProps {
  posts?: Array<Post>;
}

export default function PostList(props: PostListProps) {
  const postList = props.posts?.map((post) => (
    <$Post key={post.id}>
      <a href="/">{post.title}</a>
    </$Post>
  ));

  return <$Container>{postList}</$Container>;
}
