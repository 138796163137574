import React, { useState } from "react";
import styled from "styled-components";
import Textfield from "components/Textfield";
import Button from "components/Button";

const $Form = styled.form`
  display: flex;
  margin-bottom: 1rem;
`;

interface CreatePostFormProps {
  processing: boolean;
  onSubmit: (title: string) => void;
}

export default function CreatePostForm(props: CreatePostFormProps) {
  const [title, setTitle] = useState("");

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    await props.onSubmit(title);
    setTitle("");
  }

  return (
    <$Form onSubmit={handleSubmit}>
      <Textfield
        id="create-post"
        type="text"
        label="Create Post"
        value={title}
        onChange={setTitle}
      />
      <Button
        type="submit"
        label="Create"
        onClick={() => {}}
        disabled={props.processing}
      />
    </$Form>
  );
}
