import React, { useState } from "react";
import CreatePostForm from "./CreatePostForm";

import { useCreatePostMutation } from "./CreatePost.generated";

export default function CreatePostContainer() {
  const [processing, setProcessing] = useState(false);
  const [createPost] = useCreatePostMutation({
    update(cache, { data }) {
      const post = data?.createPost?.post;
      if (!post) return;
      cache.modify({
        fields: {
          posts: (cachedPosts) => Object.assign({}, cachedPosts),
        },
      });
    },
  });

  async function onCreatePost(title: string) {
    try {
      setProcessing(true);
      await createPost({
        variables: {
          title: title,
        },
      });
    } catch {
      // TODO: handle error
    } finally {
      setProcessing(false);
    }
  }

  return <CreatePostForm processing={processing} onSubmit={onCreatePost} />;
}
