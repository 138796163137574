import React from "react";
import CreatePost from "./components/CreatePost";

import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { usePostsQuery } from "./Posts.generated";

import PostList from "./components/PostList/PostList";

export default function Home() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { loading, data } = usePostsQuery();

  if (loading) return null;

  async function handleLogout() {
    try {
      await auth.signout();
      navigate("/login");
    } catch (error) {
      // TODO: handle auth error
    }
  }

  return (
    <>
      <h1>Home</h1>
      <PostList posts={data?.posts} />
      <CreatePost />
      <button onClick={handleLogout}>Log out</button>
    </>
  );
}
