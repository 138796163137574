import React from "react";

interface ButtonProps {
  type: "button" | "submit" | "reset";
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

Button.defaultProps = {
  type: "button",
};

export default function Button(props: ButtonProps) {
  return (
    <div>
      <button
        type={props.type}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.label}
      </button>
    </div>
  );
}
